<button 
	type="button" 
	class="btn"
	(click)="onClick()"
	[attr.disabled]="disabled() ? 'disabled' : null"
	[ngClass]="{
		'btn-primary': color() === 'primary',
		'btn-secondary': color() === 'secondary'}">
		<ng-content />
</button>
