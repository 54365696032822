import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';

export type ButtonColor = 'primary' | 'secondary';

@Component({
  standalone: true,
  selector: 'lumiron-button',
  templateUrl: 'button.component.html',
  styleUrl: './button.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class ButtonComponent {
  /**
   * Color of the button
   */
  public color = input<ButtonColor>('primary');

  /**
   * Disabled state of the button
   */
  public disabled = input<boolean>(false);

  public clicked = output();

  public onClick(): void {
    if (!this.disabled()) {
      this.clicked.emit();
    }
  }
}
